import React from 'react';
import styles from './CompareSlider.module.css'
import { useState, useRef, useEffect } from "react";
import {ReactTyped} from "react-typed";
import useMediaQuery, {Device} from "../../hooks/useMediaQuery";


interface CompareSliderProps {
    image1: string,
    image2: string,
    alt1: string,
    alt2: string
}

/**
 * A React component for comparing two images side by side with an interactive slider.
 * Users can move the slider to reveal more of one image or the other, facilitating a visual comparison.
 * The component utilizes React hooks for state management and refs to handle animations and mouse tracking.
 *
 * @param {string} image1 - URL of the first image to be displayed on the left side of the slider.
 * @param {string} image2 - URL of the second image to be displayed on the right side of the slider.
 * @param {string} alt1 - Alternate text for the first image, used for accessibility and SEO purposes.
 * @param {string} alt2 - Alternate text for the second image, used for accessibility and SEO purposes.
 * @constructor
 */

const CompareSlider = ({image1, image2, alt1, alt2} : CompareSliderProps) => {
    const initialPosition = 51.3
    const [sliderPosition, setSliderPosition] = useState(initialPosition);
    const isMobile = useMediaQuery(Device.sm)

    /*
     * - requestRef: holds the reference for the animation frame request, allowing you to cancel it on unmounting.
     * - mouseXRef: tracks the mouse's x-position relative to the slider, used to adjust the slider position.
     */
    const requestRef = useRef<number | null>(null);
    const mouseXRef = useRef<number | null>(null);
    const scrollSpeed = 0.4; // Adjust the scroll speed as needed

    /**
     * Handles mouse movement over the slider component by updating the mouseXRef with the current mouse position.
     * This position is relative to the left edge of the slider, which is essential for calculating the slider's position.
     *
     * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event - The mouse event that triggers this handler,
     *        which provides the mouse coordinates needed to compute the relative position.
     */
    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const rect = event.currentTarget.getBoundingClientRect();
        mouseXRef.current = event.clientX - rect.left;
    };

    /**
     * Handles the mouse leaving the slider component by setting mouseXRef to null.
     * This action effectively stops any further adjustments to the slider position based on mouse movement,
     * ensuring that the slider doesn't continue to move when the mouse is no longer over the slider.
     */
    const handleMouseLeave = () => {
        mouseXRef.current = null;
    };

    /**
     * Manages the animation of the slider's position based on the mouse's proximity to the slider's edges.
     * The function checks where the mouse is relative to the slider and automatically moves the slider toward
     * the initial position when the mouse is not near the edges. If the mouse is near an edge, it adjusts
     * the slider position to gradually reveal more of one image or the other.
     * This function continuously schedules itself to run using requestAnimationFrame, creating a smooth animation effect.
     */
    const animate = () => {
        const rectWidth = window.innerWidth * 0.8; // Assuming 80vw width for the slider
        const edgeThreshold = rectWidth * 0.5; // 10% of the width from the edges

        if (mouseXRef.current !== null) {
            if (mouseXRef.current < edgeThreshold) {
                // Mouse is on the left edge
                setSliderPosition((prev) => Math.max(prev + scrollSpeed, 0));
            } else if (mouseXRef.current > rectWidth - edgeThreshold) {
                // Mouse is on the right edge
                setSliderPosition((prev) => Math.min(prev - scrollSpeed, 100));
            }
        } else {
            setSliderPosition((prev) => {
                const difference = initialPosition - prev;
                if (Math.abs(difference) < scrollSpeed) {
                    return initialPosition;
                }
                return prev + (difference > 0 ? scrollSpeed : -scrollSpeed);
            });
        }

        requestRef.current = requestAnimationFrame(animate);
    };

    /**
     * Sets up and cleans up the animation loop for the slider position when the component mounts and unmounts.
     * This useEffect hooks the animate function into the component's lifecycle by starting the animation
     * using requestAnimationFrame when the component mounts. It ensures that the animation is smoothly updated,
     * and it schedules the next animation frame each time it runs.
     * On component unmount, this hook cleans up by canceling the scheduled animation frame, preventing memory leaks
     * and unnecessary computations after the component is no longer in use.
     */
    useEffect(() => {
        requestRef.current = requestAnimationFrame(animate);
        return () => {
            if (requestRef.current) {
                cancelAnimationFrame(requestRef.current);
            }
        };
    }, []);

    const getRightTitleOpacity = () => {
        if (sliderPosition <= 50) {
            return 1;
        } else {
            return Math.max(1 - (sliderPosition - 50) / 50, 0);
        }
    };

    const getLeftTitleOpacity = () => {
        if (sliderPosition >= 50) {
            return 1;
        } else {
            return Math.max(1 - (50 - sliderPosition) / 50, 0);
        }
    };


    return (
        <>
            <div className={styles.container} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
                { !isMobile ? <div className={styles.titlesContainer}>
                    <div className={styles.titleContainerLeft} style={{opacity: getLeftTitleOpacity()}}>
                        <h2 className={styles.title}>
                            Davide
                        </h2>
                    </div>
                    <div className={styles.titleContainerRight} style={{opacity: getRightTitleOpacity()}}>
                        <h2 className={styles.title}>
                            <ReactTyped
                                loop
                                typeSpeed={70}
                                backSpeed={80}
                                strings={[
                                    "Software",
                                    "Music",
                                ]}
                                smartBackspace
                                shuffle={false}
                                backDelay={1000}
                                fadeOut={false}
                                fadeOutDelay={1000}
                                loopCount={0}
                                showCursor
                                cursorChar="|"
                            />

                        </h2>
                        <h2 className={styles.title}>Engineer</h2>
                    </div>
                </div> : null}
                <div className={styles.imageContainer1}>
                    <img src={image1} alt={alt1} className={styles.image} />
                </div>
                <div className={styles.imageContainer2}
                     style={{clipPath: `inset(0 ${100 - sliderPosition}% 0 0)`}}>
                    <img src={image2} alt={alt2} className={styles.image}/>
                </div>
            </div>

        </>
    );
};

export default CompareSlider;