import React, { useContext } from 'react';
import { Timeline } from '@mui/lab';
import { AppContext } from "../../../context/AppContext";
import { CustomizedTimelineItem } from "../../index";
import styles from './TimelineEducation.module.css';
import useMediaQuery, {Device} from "../../../hooks/useMediaQuery";

const TimelineEducation = () => {
    const appData = useContext(AppContext);
    const isMobile = useMediaQuery(Device.sm);

    return (
        <>
            <h1 className={styles.title}>
                Education
            </h1>

        <Timeline
            position={isMobile ? "right" : "alternate"}
            className={styles.timeline}
        >
            {appData.educationList.map((x, i) => (
                <CustomizedTimelineItem
                    key={i}
                    img={x.img}
                    title={x.title}
                    period={x.period}
                    place={x.place}
                    external={x.external}
                    index={i}
                    isLast={i === appData.educationList.length - 1}
                />
            ))}
        </Timeline>
        </>
    );
};

export default TimelineEducation;