import React from 'react';
import styles from './Header.module.css'

/* Images import */
import headerCode from '../../../../data/img/header-code.webp';
import headerHobby from '../../../../data/img/header-hobby.webp';
import {CompareSlider} from "../../../index";

const Header = () => {
    return (
        <>
            <div className={styles.container}>
                <CompareSlider image1={headerCode} image2={headerHobby} alt1={"developer"} alt2={"hobby"}/>
            </div>
        </>
    );
};

export default Header;