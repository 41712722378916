import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import styled from 'styled-components';

// Interfaces for prop types
interface SubNavItem {
    title: string;
    path: string;
    hashLink: string;
    icon: JSX.Element;
}

interface SidebarSocialProps {
    sidebar: boolean;
}

interface NavItem {
    title: string;
    path: string;
    hashLink: string;
    icon: JSX.Element;
    iconClosed?: JSX.Element;
    iconOpened?: JSX.Element;
    subNav?: SubNavItem[];
}

interface SubMenuProps {
    item: NavItem;
    sidebar: boolean;
}

// Styled components
const SidebarSocial = styled(Link)<SidebarSocialProps>`
    display: flex;
    color: var(--black);
    justify-content: ${({ sidebar }) => (sidebar ? 'flex-start' : 'center')};
    align-items: center;
    padding: ${({ sidebar }) => (sidebar ? '0.5rem 1rem' : '0.5rem')};
    list-style: none;
    height: 2rem;
    text-decoration: none;
    font-size: 1.5rem;
    margin: 1rem;
    border-radius: 15px;

    &:hover {
        background: var(--lightBlue);
        cursor: pointer;
        color: var(--blue);
    }
`;

const SidebarLabel = styled.span`
    margin-left: 20%;
    text-align: left;
    font-size: 1rem;
`;

const scrollWithOffset = (el: HTMLElement) => {
    console.log("scrollWithOffset called");
    const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
    const yOffset = -80; // Adjust this value to the height of your fixed header
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

const SubMenu = ({ item, sidebar }: SubMenuProps) => {
    const [subnav, setSubnav] = useState(false);

    const showSubnav = () => setSubnav(!subnav);

    return (

        <SidebarSocial
            to={`${item.path}#${item.hashLink}`}
            onClick={() => {

                if (item.subNav) showSubnav();
            }}
            sidebar={sidebar}
            scroll={(el: HTMLElement) => {
                scrollWithOffset(el);
            }}
        >
            {!sidebar ? <div className={"tooltip"}>
                {item.icon}
                <span className={"tooltiptext"}>{item.title}</span>
            </div> : item.icon
            }

            {sidebar && <SidebarLabel>{item.title}</SidebarLabel>}
        </SidebarSocial>
    );
};

export default SubMenu;
