import React from 'react';
import TimelineEmployment from "../components/PagesSections/Employment/TimelineEmployment";
import useMediaQuery, {Device} from "../hooks/useMediaQuery";
import styles from "./Employment.module.css"
const Employment = () => {
    const isMobile = useMediaQuery(Device.sm);
    return (
        <>
            {!isMobile ? <section>
                <TimelineEmployment/>
            </section> : <div className={styles.container}> <TimelineEmployment/> </div>}
        </>

    );
};

export default Employment;