import React from 'react';
import "@fontsource/maven-pro";
import './App.css';
import {AppContext, useAppContext} from "./context/AppContext";

import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
/* Components */
import {Bottombar, Footer, Header, Sidebar} from "./components";

/* Pages */
import {Home, SkillsPage, ScrollToTop, Employment} from './pages'
import Education from "./pages/Education";
import useMediaQuery, {Device} from "./hooks/useMediaQuery";

function App() {

    const appData = useAppContext();
    const isMobile = useMediaQuery(Device.sm);
    return (
        <>
            <AppContext.Provider value={appData}>

                <Router>
                    <ScrollToTop/>
                    {!isMobile ? <Sidebar/> : null}

                    <Header/>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/skills" element={<SkillsPage/>}/>
                        <Route path="/employment" element={<Employment/>}/>
                        <Route path="/education" element={<Education/>}/>
                    </Routes>
                    <Footer/>
                    {isMobile ? <Bottombar/> : null}
                </Router>
            </AppContext.Provider>
        </>
    );
}

export default App;
