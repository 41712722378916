import React from 'react';
import {Bio, Projects, ExperienceShort} from "../components";

const Home = () => {
    return (
        <>
            <section>
                <Bio/>
                <ExperienceShort/>
                <Projects/>
            </section>
        </>
    );
};

export default Home;