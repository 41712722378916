import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
    return (
        <div className={styles.container}>
            <hr className={styles.hr}/>
            © 2024 Davide Lista • davide.lista@outlook.com • P.IVA: 01079450142
        </div>
    );
};

export default Footer;