import React, {useContext, useState, useEffect, useRef} from 'react';
import {ExpandableParagraph, ProjectCard} from "../../../index";
import styles from "./Projects.module.css";
import useMediaQuery, {Device} from "../../../../hooks/useMediaQuery";
import {AppContext} from "../../../../context/AppContext";
import * as VscIcon from "react-icons/vsc";

export interface Project {
    title: string;
    description: string;
    tag: string;
    datore: string;
    internal: string;
    external: string;
    github: string;
    img: string;
    languages: string[];
    link: string;
}

type GroupType = 'primary' | 'secondary';

interface ProjectGroup {
    type: GroupType;
    projects: Project[];
    startIndex: number;
}

const Projects = () => {
    const appData = useContext(AppContext);
    const isPc = useMediaQuery(Device.xl);
    const isMobile = useMediaQuery(Device.sm);
    const [filter, setFilter] = useState<string>("All");
    const filtersList = ["All", "E-learning", "Development", "Art"];
    const backgroundStyle = ["#C6DEF1", "#F0EFEB", "var(--white)", "#C9E4DE", "#F5EFFF", "#F7D9C4", "#F0EFEB", "var(--black)", "#C9E4DE", "#F5EFFF"];
    const colorStyle = ["var(--black)", "#var(--black)", "var(--black)", "#var(--black)", "var(--black)", "var(--black)", "var(--black)", "var(--white)", "var(--black)", "var(--black)"];

    // Funzione per gestire l'Intersection Observer
    const useIntersectionObserver = (options = {}) => {
        const containerRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add(styles.visible);
                    }
                });
            }, { threshold: 0.1, ...options });

            const elements = containerRef.current?.querySelectorAll(`.${styles.projectCard}`);
            if (elements) {
                elements.forEach((el) => observer.observe(el));
            }

            return () => {
                if (elements) {
                    elements.forEach((el) => observer.unobserve(el));
                }
            };
        }, [filter]); // Ricrea l'observer quando cambia il filtro

        return containerRef;
    };

    const containerRef = useIntersectionObserver();

    const getFilteredProjects = () => {
        return filter === "All"
            ? appData.projectsList
            : appData.projectsList.filter(x => x.tag === filter);
    };

    const createProjectGroups = (projects: Project[]): ProjectGroup[] => {
        const groups: ProjectGroup[] = [];
        let currentIndex = 0;

        while (currentIndex < projects.length && projects.length - currentIndex >= 11) {
            groups.push({
                type: 'primary',
                projects: projects.slice(currentIndex, currentIndex + 11),
                startIndex: currentIndex
            });
            currentIndex += 11;
        }

        while (currentIndex < projects.length) {
            const remainingProjects = projects.slice(currentIndex, currentIndex + 4);
            if (remainingProjects.length > 0) {
                groups.push({
                    type: 'secondary',
                    projects: remainingProjects,
                    startIndex: currentIndex
                });
            }
            currentIndex += 4;
        }

        return groups;
    };

    const renderProjectCard = (project: Project, index: number, startIndex: number, groupType: GroupType) => {
        const styleIndex = groupType === 'primary' ? index : index + 11;
        const displayIndex = startIndex + index;

        return (
            <div
                key={displayIndex}
                className={`${styles[`box${styleIndex + 1}`]} ${styles.projectCard}`}
                style={{
                    backgroundColor: backgroundStyle[displayIndex % 10],
                    color: colorStyle[displayIndex % 10]
                }}
            >
                <div className={styles[`gridBox${styleIndex + 1}`]}>
                    <img
                        src={require('../../../../data/img/projects/' + project.img)}
                        alt={project.img}
                        className={styles.boxB}
                    />
                    <div className={styles.boxA}>
                        <h2 className={styles.titleProject}>{project.title}</h2>
                        <h4 className={styles.datore}>{project.datore}</h4>
                        <p><ExpandableParagraph>{project.description}</ExpandableParagraph></p>
                        <div className={styles.languages}>
                            {project.languages.map((x, i) => (
                                <div
                                    key={i}
                                    className={styles.language}
                                    style={{background: backgroundStyle[(displayIndex + 1 + i) % 10], color: colorStyle[(displayIndex + 1 + i) % 10]}}
                                >
                                    {x}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.boxC}>
                        <div className={styles.links}>
                            {project.internal && (
                                <a target={"_blank"} className={styles.icon} style={{color: colorStyle[displayIndex % 10]}} href={project.internal}>
                                    <VscIcon.VscLink/>
                                </a>
                            )}
                            {project.external && (
                                <a target={"_blank"} className={styles.icon} style={{color: colorStyle[displayIndex % 10]}} href={project.external}>
                                    <VscIcon.VscGlobe/>
                                </a>
                            )}
                            {project.github && (
                                <a target={"_blank"} className={styles.icon} style={{color: colorStyle[displayIndex % 10]}} href={project.github}>
                                    <VscIcon.VscGithubAlt/>
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const filteredProjects = getFilteredProjects();
    const projectGroups = createProjectGroups(filteredProjects);

    return (
        <div className={styles.container} id="portfolio">
            <h1 className={styles.title}>Portfolio</h1>

            <div className={styles.filters}>
                {filtersList.map((x) => (
                    <div
                        key={x}
                        className={filter === x ? styles.filterActive : styles.filter}
                        onClick={() => setFilter(x)}
                    >
                        {x}
                    </div>
                ))}
            </div>

            <div className={styles.gridSection} ref={containerRef}>
                {projectGroups.map((group) => (
                    <div
                        key={group.startIndex}
                        className={group.type === 'primary' ? styles.gridPatternPrimary : styles.gridPatternSecondary}
                    >
                        {group.projects.map((project, index) =>
                            renderProjectCard(project, index, group.startIndex, group.type)
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Projects;