import React from 'react';
import styles from "./Bio.module.css";
import Me from "../../../../data/img//cv-foto.webp";
import useMediaQuery, {Device} from '../../../../hooks/useMediaQuery';
import {AiOutlineCloudDownload} from "react-icons/ai";

import CV from "../../../../data/documents/davide-lista-cv-nov24.pdf";
import backgroundImage from "../../../../data/img/background-cards.webp"
import {Card, Button, Overview, ExpandableParagraph} from "../../../index";

const Bio: React.FC = (props) => {
    const isMobile = useMediaQuery(Device.sm);
    return (
        <>
            {!isMobile ? <h1 className={styles.title}>
                Bio
            </h1> : null}
            {!isMobile ?
                <div className={styles.section}>
                    <Overview/>
                    <div className={styles.rightSection}>
                        <Card width={"auto"} height={"auto"} padding={""} color={""} background={""} imgBg={true}
                              imageUrl={backgroundImage}>

                            <div className={styles.container} id='about'>


                                <div className={styles.personal}>
                                    <img src={Me} className={styles.image}
                                         alt={"Peccato, non puoi vedere quanto sono bello"}/>

                                    <div className={styles.text}>
                                        <p>There are few things that never cease to amaze me, and two of them are
                                            technological innovation and music. For this reason, after graduating in
                                            Computer
                                            Engineering, I chose to pursue a path in Music and Acoustic Engineering at
                                            the
                                            Politecnico di Milano. This choice represents the perfect fusion between my
                                            two
                                            souls: the artistic one, cultivated for years by playing classical guitar,
                                            and the
                                            more technical one, passionate about technology.</p>

                                        <p>Moreover, I have always loved to challenge myself and apply concretely what I
                                            was
                                            learning. From a young age, I accumulated several work experiences, starting
                                            at the
                                            age of 15 in a lutherie shop. For the same reason, I developed a passion for
                                            the
                                            field of education. Over the years, I have deepened this interest by working
                                            with
                                            both young people and established professionals, further enriching my skills
                                            and
                                            knowledge.</p>

                                    </div>
                                    <div className={styles.button}>
                                        <a href={CV} target={"_blank"}>
                                            <Button buttonStyle='primary-btn' onClick={() => {
                                            }}>
                                                <AiOutlineCloudDownload size={25} style={{marginRight: "0.5rem"}}/>
                                                {isMobile ? "CV" : "Curriculum vitae"}
                                            </Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div> :

                <div className={styles.section}>
                    <Card width={"100%"} height={"auto"} padding={""} color={""} background={"white"} imgBg={false}
                          imageUrl={""}>
                        <div className={styles.container} id='about'>


                            <div className={styles.personal}>
                                {!isMobile ? <img src={Me} className={styles.image}
                                                  alt={"Peccato, non puoi vedere quanto sono bello"}/> :

                                    <h3 className={styles.title}>
                                        Bio
                                    </h3>}

                                <div className={styles.text}>
                                    <ExpandableParagraph>
                                        <p>There are few things that never cease to amaze me, and two of them are
                                            technological innovation and music. For this reason, after graduating in
                                            Computer
                                            Engineering, I chose to pursue a path in Music and Acoustic Engineering at
                                            the
                                            Politecnico di Milano. This choice represents the perfect fusion between my
                                            two
                                            souls: the artistic one, cultivated for years by playing classical guitar,
                                            and the
                                            more technical one, passionate about technology.</p>

                                        <p>Moreover, I have always loved to challenge myself and apply concretely what I
                                            was
                                            learning. From a young age, I accumulated several work experiences, starting
                                            at the
                                            age of 15 in a lutherie shop. For the same reason, I developed a passion for
                                            the
                                            field of education. Over the years, I have deepened this interest by working
                                            with
                                            both young people and established professionals, further enriching my skills
                                            and
                                            knowledge.</p>
                                    </ExpandableParagraph>
                                </div>
                                <div className={styles.button}>
                                    <a href={CV} target={"_blank"}>
                                        <Button buttonStyle='primary-btn' onClick={() => {
                                        }}>
                                            <AiOutlineCloudDownload size={25} style={{marginRight: "0.5rem"}}/>
                                            {isMobile ? "CV" : "Curriculum vitae"}
                                        </Button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Card>
                    <Overview/>
                </div>
            }
        </>
    );
}

export default Bio;
