import React, {useContext, useEffect, useState} from 'react';
import backgroundImage from "../../../../data/img/background-cards.webp";
import styles from "./ExperienceShort.module.css";
import useMediaQuery, {Device} from "../../../../hooks/useMediaQuery";
import {AppContext} from "../../../../context/AppContext";
import {Card} from "../../../index";

interface Experience {
    img: string;
}

const ExperienceShort = () => {
    const isMobile = useMediaQuery(Device.sm);
    const appData = useContext(AppContext);
    const [isVisible, setIsVisible] = useState(true);

    const uniqueExperiences = Array.from(new Set(
        appData.experiencesList
            .filter((experience: Experience) => experience.img !== "")
            .map(experience => experience.img)
    )).map(img => ({img}));

    // Duplicare gli elementi per un effetto continuo
    const duplicatedExperiences = [...uniqueExperiences, ...uniqueExperiences];

    useEffect(() => {
        const timer = setInterval(() => {
            setIsVisible(false);
            setTimeout(() => setIsVisible(true), 100);
        }, 60000); // Resetta l'animazione ogni 20 secondi

        return () => clearInterval(timer);
    }, []);

    return (
        <>

            <div className={styles.section}>
                <Card
                    width="auto"
                    height="auto"
                    color=""
                    padding=""
                    background=""
                    imgBg={true}
                    imageUrl={backgroundImage}

                >
                    <div className={styles.container}>
                        <div className={styles.logoContainer}>
                            <div className={`${styles.carouselWrapper} ${isVisible ? styles.visible : styles.hidden}`}>
                                <div className={styles.carouselTrack}>
                                    {duplicatedExperiences.map((experience: Experience, index: number) => (
                                        <img
                                            key={index}
                                            src={require('../../../../data/img/logo/' + experience.img)}
                                            alt={experience.img}
                                            className={styles.image}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default ExperienceShort;