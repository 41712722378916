import React, { ReactNode, useEffect, useState } from 'react';
import styles from './Card.module.css';

interface CardProps {
    children: ReactNode;
    width: string;
    height: string;
    padding: string;
    color: string;
    background: string;
    imgBg?: boolean;
    imageUrl?: string;
}

const Card: React.FC<CardProps> = ({children, width = "auto", height = "auto", padding = "5% 0", color, background, imgBg = false, imageUrl = ""}) => {

    const [isHovered, setIsHovered] = useState(false);
    const [currentColor, setCurrentColor] = useState("");
    const [currentBackground, setCurrentBackground] = useState("");

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    useEffect(() => {
        if (isHovered) {
            setCurrentColor(color);
            setCurrentBackground(background);
        } else {
            setCurrentColor("");
            setCurrentBackground("");
        }
    }, [isHovered, color, background]);

    return (
        <div className={styles.container}
             style={{
                 width: width,
                 height: height,
                 padding: padding,
                 color: currentColor,
                 background: imgBg && imageUrl ? `url(${imageUrl})` : currentBackground,
                 backgroundSize: 'cover',
                 backgroundPosition: 'left'
             }}
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}>
            {children}
        </div>
    );
};

export default Card;
