import React, {useContext, useEffect, useRef, useState} from 'react';
import {Card} from "../../index";
import backgroundImage from "../../../data/img/background-cards.webp";
import {AppContext} from "../../../context/AppContext";
import styles from "./Skills.module.css";
import useMediaQuery, {Device} from "../../../hooks/useMediaQuery";
import {
    DiReact,
    DiPython,
    DiJava,
    DiPhp,
    DiHtml5,
    DiCss3,
    DiMysql,
    DiLinux,
    DiWindows,
    DiDatabase
} from "react-icons/di";
import {SiNextdotjs, SiFlask, SiDjango, SiC, SiMacos, SiPowerbi, SiPowerapps, SiMoodle} from "react-icons/si";
import {
    TbBrandMysql,
    TbBrandJavascript,
    TbSquareLetterPFilled,
    TbSquareRoundedLetterM,
    TbSquareLetterSFilled
} from "react-icons/tb";
import {IoCodeSlashOutline, IoLogoWordpress} from "react-icons/io5";
import { BsPersonVideo2 } from "react-icons/bs";

interface AnimatedProgressBarProps {
    value: number;
}

const AnimatedProgressBar = ({value}: AnimatedProgressBarProps) => {
    const [progressValue, setProgressValue] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const progressRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect(); // Scolleghiamo l'observer dopo che è diventato visibile
                }
            },
            {
                threshold: 0.1 // L'elemento è considerato visibile quando il 10% è nel viewport
            }
        );

        if (progressRef.current) {
            observer.observe(progressRef.current);
        }

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        if (isVisible && progressValue < value) {
            const timer = setTimeout(() => {
                setProgressValue(prev => Math.min(prev + 1, value));
            }, 20);
            return () => clearTimeout(timer);
        }
    }, [progressValue, value, isVisible]);

    return (
        <div ref={progressRef}>
            <progress
                className={styles.progressBar}
                value={progressValue}
                max={100}
            />
        </div>
    );
};

const getSkillIcon = (skillName: string) => {
    const iconProps = {size: 20, className: styles.skillIcon};

    const iconMap: { [key: string]: JSX.Element } = {
        "React.js": <DiReact {...iconProps} />,
        "Next.js": <SiNextdotjs {...iconProps} />,
        "PHP": <DiPhp {...iconProps} />,
        "Javascript": <TbBrandJavascript {...iconProps} />,
        "HTML": <DiHtml5 {...iconProps} />,
        "CSS": <DiCss3 {...iconProps} />,
        "SQL": <DiDatabase {...iconProps} />,
        "Django": <SiDjango {...iconProps} />,
        "Flask": <SiFlask {...iconProps} />,
        "Linguaggio C": <SiC {...iconProps} />,
        "Java SE": <DiJava {...iconProps} />,
        "Python": <DiPython {...iconProps} />,
        "Processing": <TbSquareLetterPFilled {...iconProps} />,
        "VHDL": <IoCodeSlashOutline {...iconProps}/>,
        "MATLAB": <TbSquareRoundedLetterM {...iconProps}/>,
        "SuperCollider": <TbSquareLetterSFilled {...iconProps}/>,
        "Linux": <DiLinux {...iconProps} />,
        "Windows": <DiWindows {...iconProps} />,
        "MacOS": <SiMacos {...iconProps} />,
        "Power BI": <SiPowerbi {...iconProps} />,
        "Power Apps": <SiPowerapps {...iconProps} />,
        "Wordpress": <IoLogoWordpress {...iconProps} />,
        "Moodle": <SiMoodle {...iconProps} />,
        "Active Presenter": <BsPersonVideo2 {...iconProps} />,


    };

    return iconMap[skillName] || null;
};

interface SkillItem {
    name: string;
    value: number;
}

interface Skill {
    name: string;

    [key: string]: Array<SkillItem> | string; // per permettere la proprietà dinamica (Web, Coding, Software)
}

const Skills = () => {
    const appData = useContext(AppContext);
    const isMobile = useMediaQuery(Device.sm);

    return (
        <div className={styles.container}>

            {appData.knowledgesList.map((x: Skill, i) => {
                const category = x.name;
                const skillArray = x[category] as Array<SkillItem>;
                return (
                    <>
                        {!isMobile ? <h1 className={styles.title}>
                            {category}
                        </h1> : null}
                        <Card width="auto"
                              height="auto"
                              color=""
                              padding=""
                              background="white"
                              imgBg={false}
                              imageUrl={""} key={i}>
                            <div className={styles.skills}>
                                {isMobile ? <h3 className={styles.title} id={"about"}>
                                    {category}
                                </h3> : null}
                                {skillArray?.map((y, j) => {
                                    return (
                                        <div className={styles.progressItem} key={j}>
                                            <div className={styles.label}>
                                                <div className={styles.icon} >{getSkillIcon(y.name)}</div>
                                                {y.name}
                                            </div>
                                            <AnimatedProgressBar value={y.value}/>
                                        </div>

                                    )
                                })}
                            </div>
                        </Card>

                        {i < appData.knowledgesList.length - 1 ? <hr className={styles.hr}/> : null}

                    </>
                )
            })}

        </div>
    );
};

export default Skills;