import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import {Link, useNavigate} from 'react-router-dom';
import {BottombarData, SidebarSocialData, SubBottombarData} from '../MenuData';
import SubMenu from '../Sidebar/SubMenu';
import SocialMenu from '../Sidebar/SocialMenu';
import {IconContext} from 'react-icons/lib';
import styles from '../Menu.module.css'

/* IMPORT IMAGES */
import LogoSmall from '../../../data/img/logo/davidelista/dl-blu-circle.svg'

interface MenuItemProps {
    position?: {
        x: number;
        y: number;
    };
}

interface RadialMenuProps {
    isOpen: boolean;
}

interface LogoButtonProps {
    isOpen: boolean;
}

const BottombarNav = styled.nav`
    background: var(--white);
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0 !important;
    left: 0;
    transition: 350ms ease;
    z-index: 10;
    border-radius: 5px;
`;

const BottombarWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const MenuItem = styled(Link)<MenuItemProps>`
    position: absolute;
    width: 4rem;
    height: 4rem;
    font-size: 1.5rem;
    background: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    z-index: ${props => props.position ? '30' : '20'};
    touch-action: manipulation; // Migliora la gestione del touch
    -webkit-tap-highlight-color: transparent; // Rimuove l'highlight su iOS

    /* Aumentiamo l'area touchable mantenendo le dimensioni visive */
    &::after {
        content: '';
        position: absolute;
        top: -10px;
        left: -10px;
        right: -10px;
        bottom: -10px;
    }

    &:hover {
        background: #f5f5f5;
        transform: scale(1.1);
    }

    ${props => props.position && `
        transform: translate(
            ${props.position.x}px,
            ${props.position.y}px
        );
    `}
`;

const LogoButton = styled.button<LogoButtonProps>`
    width: 64px;
    height: 64px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
    transform: rotate(${props => props.isOpen ? '360deg' : '0deg'});

    img {
        width: 48px;
        height: 48px;
    }
`;

const RadialMenu = styled.div<RadialMenuProps>`
    position: absolute;
    bottom: 6rem;
    left: 43%;
    z-index: 25;
    transform: translateX(-50%);
    transition: transform 0.3s ease;
    display: ${props => props.isOpen ? 'block' : 'none'};
`;

const Bottombar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const getItemPosition = useCallback((index: number, total: number) => {
        const radius = 120;
        const startAngle = 30;
        const endAngle = 150;
        const angleRange = endAngle - startAngle;
        const angleStep = angleRange / (total - 1);
        const angleRad = (startAngle + (index * angleStep)) * Math.PI / 180;

        return {
            x: radius * Math.cos(angleRad),
            y: -radius * Math.sin(angleRad)
        };
    }, []);

    const handleMenuItemClick = useCallback((path: string) => {
        setIsOpen(false);
        navigate(path);
    }, [navigate]);

    return (
        <IconContext.Provider value={{color: 'var(--black)'}}>
            <BottombarNav>
                <BottombarWrap>
                    {BottombarData.slice(0, 2).map((item, index) => (
                        <SubMenu item={item} sidebar={false} key={index}/>
                    ))}
                    <RadialMenu isOpen={isOpen}>
                        {SubBottombarData.map((item, index) => (
                            <MenuItem
                                key={index}
                                to={item.path}
                                position={getItemPosition(index, SubBottombarData.length)}
                                onClick={(e) => {
                                    e.preventDefault(); // Preveniamo il comportamento default
                                    handleMenuItemClick(item.path);
                                }}
                                onTouchEnd={(e) => {
                                    e.preventDefault();
                                    handleMenuItemClick(item.path);
                                }}
                            >
                                {item.icon}
                            </MenuItem>
                        ))}
                    </RadialMenu>
                    <LogoButton
                        isOpen={isOpen}
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <img src={LogoSmall} alt="Logo"/>
                    </LogoButton>
                    {BottombarData.slice(2, 4).map((item, index) => (
                        <SubMenu item={item} sidebar={false} key={index}/>
                    ))}
                </BottombarWrap>
            </BottombarNav>
        </IconContext.Provider>
    );
};

export default Bottombar;
