import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { SidebarData, SidebarSocialData} from '../MenuData';
import SubMenu from './SubMenu';
import SocialMenu from './SocialMenu';
import { IconContext } from 'react-icons/lib';
import styles from '../Menu.module.css'

/* IMPORT IMAGES */
import LogoSmall from '../../../data/img/logo/davidelista/dl-blu-small.png'
import Logo from '../../../data/img/logo/davidelista/dl-blu.png'

// Define interface for the styled components with dynamic props if needed
interface SidebarNavProps {
    sidebar: boolean;
}

const NavIcon = styled(Link)`
    font-size: 2rem;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; // Aggiungi per chiarezza che è cliccabile
`;


const SidebarNav = styled.nav<SidebarNavProps>`
    background: var(--white);
    width: ${({ sidebar }) => (sidebar ? '12rem' : '5rem')}; // Larghezza piena quando aperta, ridotta a 80px per solo icone
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    transition: 350ms ease;
    z-index: 10;
    overflow: hidden; // Nasconde elementi fuori dall'area visibile quando chiusa
    border-radius: 5px;
`;
const SidebarWrap = styled.div`
  width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Sidebar = () => {
    const [sidebar, setSidebar] = useState<boolean>(false);

    const showSidebar = () => setSidebar(!sidebar);

    return (
        <>
            <IconContext.Provider value={{ color: 'var(--black)' }}>


                <SidebarNav sidebar={sidebar}>

                    <SidebarWrap>
                        <div>
                            <NavIcon to="#">
                                {!sidebar ? <img src={LogoSmall} alt={"Logo"} className={styles.image}
                                                 onClick={showSidebar}/> :
                                    <img src={Logo} alt={"Logo"} className={styles.image} onClick={showSidebar}/>}
                            </NavIcon>
                            {SidebarData.map((item, index) => {
                                return <SubMenu item={item} sidebar={sidebar} key={index} />;
                            })}


                        </div>
                        <div style={{display: "flex", flexDirection: "column"}}>

                            {SidebarSocialData.map((item, index) => {
                                return <SocialMenu item={item} sidebar={sidebar} key={index}/>;
                            })}
                        </div>
                    </SidebarWrap>

                </SidebarNav>
            </IconContext.Provider>
        </>
    );
};

export default Sidebar;
