import React from 'react';
import { SiAboutdotme } from "react-icons/si";
import * as LiaIcons from "react-icons/lia";
import * as PiIcons from "react-icons/pi";
import * as VscIcons from "react-icons/vsc";
import { IconContext } from "react-icons";
import styles from './Menu.module.css'

interface NavItem {
    title: string;
    path: string;
    hashLink: string
    icon: JSX.Element;
    iconClosed?: JSX.Element;
    iconOpened?: JSX.Element;
    subNav?: SubNavItem[];
}

interface SubNavItem {
    title: string;
    path: string;
    hashLink: string;
    icon: JSX.Element;
    cName?: string;
}

export const SidebarData: NavItem[] = [
    {
        title: 'Home',
        path: '/',
        hashLink: '',
        icon: <IconContext.Provider value={{ className: "top-react-icons" }}><VscIcons.VscHome className={styles.icon}/> </IconContext.Provider>,
        iconClosed: <IconContext.Provider value={{ className: "top-react-icons" }}><LiaIcons.LiaArrowDownSolid/></IconContext.Provider>,
        iconOpened: <IconContext.Provider value={{ className: "top-react-icons" }}><LiaIcons.LiaArrowUpSolid /></IconContext.Provider>,
    },
    {
        title: 'About',
        path: '/',
        hashLink: 'about',
        icon: <IconContext.Provider value={{ className: "top-react-icons" }}><SiAboutdotme /></IconContext.Provider>,
        iconClosed: <IconContext.Provider value={{ className: "top-react-icons" }}><LiaIcons.LiaArrowDownSolid/></IconContext.Provider>,
        iconOpened: <IconContext.Provider value={{ className: "top-react-icons" }}><LiaIcons.LiaArrowUpSolid /></IconContext.Provider>,
    },
    {
        title: 'Portfolio',
        path: '/',
        hashLink: 'portfolio',
        icon: <IconContext.Provider value={{ className: "top-react-icons" }}><VscIcons.VscGist /></IconContext.Provider>
    },
    {
        title: 'Skills',
        path: '/skills',
        hashLink: '',
        icon: <IconContext.Provider value={{ className: "top-react-icons" }}><LiaIcons.LiaSwatchbookSolid/></IconContext.Provider>
    },
    {
        title: 'Employment',
        path: '/employment',
        hashLink: '',
        icon: <IconContext.Provider value={{ className: "top-react-icons" }}><LiaIcons.LiaUserLockSolid/></IconContext.Provider>
    },
    {
        title: 'Education',
        path: '/education',
        hashLink: '',
        icon: <IconContext.Provider value={{ className: "top-react-icons" }}><PiIcons.PiStudentFill/></IconContext.Provider>
    }
];


export const SidebarSocialData: NavItem[] = [
    {
        title: 'Github',
        path: 'https://github.com/davelista',
        hashLink:'',
        icon: <IconContext.Provider value={{ className: "top-react-icons" }}><VscIcons.VscGithubAlt/></IconContext.Provider>,
    },
    {
        title: 'Linkedin',
        path: 'https://www.linkedin.com/in/davide-lista/',
        hashLink: '',
        icon: <IconContext.Provider value={{ className: "top-react-icons" }}><LiaIcons.LiaLinkedin/></IconContext.Provider>,
    }

]

export const BottombarData: NavItem[] = [
    {
        title: 'Home',
        path: '/',
        hashLink: '',
        icon: <IconContext.Provider value={{ className: "top-react-icons" }}><VscIcons.VscHome className={styles.icon}/> </IconContext.Provider>,
        iconClosed: <IconContext.Provider value={{ className: "top-react-icons" }}><LiaIcons.LiaArrowDownSolid/></IconContext.Provider>,
        iconOpened: <IconContext.Provider value={{ className: "top-react-icons" }}><LiaIcons.LiaArrowUpSolid /></IconContext.Provider>,
    },
    {
        title: 'Portfolio',
        path: '/',
        hashLink: 'portfolio',
        icon: <IconContext.Provider value={{ className: "top-react-icons" }}><VscIcons.VscGist /></IconContext.Provider>
    },
    {
        title: 'Github',
        path: 'https://github.com/davelista',
        hashLink:'',
        icon: <IconContext.Provider value={{ className: "top-react-icons" }}><VscIcons.VscGithubAlt/></IconContext.Provider>,
    },
    {
        title: 'Linkedin',
        path: 'https://www.linkedin.com/in/davide-lista/',
        hashLink: '',
        icon: <IconContext.Provider value={{ className: "top-react-icons" }}><LiaIcons.LiaLinkedin/></IconContext.Provider>,
    }
];

export const SubBottombarData: NavItem[] = [
    {
        title: 'About',
        path: '/',
        hashLink: 'about',
        icon: <IconContext.Provider value={{ className: "top-react-icons" }}><SiAboutdotme /></IconContext.Provider>,
        iconClosed: <IconContext.Provider value={{ className: "top-react-icons" }}><LiaIcons.LiaArrowDownSolid/></IconContext.Provider>,
        iconOpened: <IconContext.Provider value={{ className: "top-react-icons" }}><LiaIcons.LiaArrowUpSolid /></IconContext.Provider>,
    },
    {
        title: 'Skills',
        path: '/skills',
        hashLink: '',
        icon: <IconContext.Provider value={{ className: "top-react-icons" }}><LiaIcons.LiaSwatchbookSolid/></IconContext.Provider>
    },
    {
        title: 'Employment',
        path: '/employment',
        hashLink: '',
        icon: <IconContext.Provider value={{ className: "top-react-icons" }}><LiaIcons.LiaUserLockSolid/></IconContext.Provider>
    },
    {
        title: 'Education',
        path: '/education',
        hashLink: '',
        icon: <IconContext.Provider value={{ className: "top-react-icons" }}><PiIcons.PiStudentFill/></IconContext.Provider>
    }
];
