import React from 'react';
import {Skills} from "../components";


const SkillsPage = () => {
    return (
        <>
            <section>
                <Skills/>
            </section>
        </>
    );
};

export default SkillsPage;