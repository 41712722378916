import React from 'react';
import {TimelineEducation} from "../components";


const Education = () => {
    return (
        <>
            <TimelineEducation/>
        </>
    );
};

export default Education;