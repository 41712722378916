import React, {useContext} from 'react';
import {Timeline} from '@mui/lab';
import {AppContext} from "../../../context/AppContext";
import {CustomizedTimelineItem} from "../../index";
import styles from './TimelineEmployment.module.css';
import useMediaQuery, {Device} from "../../../hooks/useMediaQuery";

const TimelineEmployment = () => {
    const appData = useContext(AppContext);
    const isMobile = useMediaQuery(Device.sm);

    return (
        <>
            <h1 className={styles.title}>
                Employment
            </h1>

            <Timeline
                position={isMobile ? "right" : "alternate"}
                className={styles.timeline}
            >
                {appData.experiencesList.map((x, i) => (
                    <CustomizedTimelineItem
                        key={i}
                        img={x.img}
                        title={x.title}
                        period={x.period}
                        place={x.place}
                        external={x.external}
                        index={i}
                        isLast={i === appData.experiencesList.length - 1}
                    />
                ))}
            </Timeline>
        </>
    );
};

export default TimelineEmployment;