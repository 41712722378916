import React from 'react';
import './Button.css'

const STYLES = ['primary-btn', 'secondary-btn'];

interface ButtonProps {
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
    children: any,
    buttonStyle: string
}

const Button = ({onClick, children, buttonStyle}: ButtonProps) => {
    //Se è stato definito uno stile del bottone inserisci lo stile altrimenti consideri il primo nell'array (btn primary)
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];

    //Creo un botton con le proprietà di stile, la proprietà di onClick e type. Children è il testo del bottone
    return (
        <button className={`${checkButtonStyle}`} onClick={onClick}>
            {children}
        </button>
    );
};

export default Button;