import React, {useContext, useState, useEffect} from 'react';
import styles from './Overview.module.css';
import useMediaQuery, {Device} from '../../../../hooks/useMediaQuery';
import {AppContext} from '../../../../context/AppContext';
import {AnimatedCounter, Card} from "../../../index";
import * as VscIcons from "react-icons/vsc";
import * as LiaIcons from "react-icons/lia";
import {PiCoffeeLight} from "react-icons/pi";

const Overview: React.FC = () => {
    const appData = useContext(AppContext);
    const isMobile = useMediaQuery(Device.sm);
    const [projects, setProjects] = useState<number>(0);
    const [experience, setExperience] = useState<number>(0);
    const [coffee, setCoffee] = useState<number>(0);
    const [cardDimension, setCardDimension] = useState("6.5rem");

    let date1 = new Date(new Date().getFullYear(), 0, 1);
    let date2 = new Date();
    let differenceInTime = date2.getTime() - date1.getTime();
    let differenceInDate = Math.round(differenceInTime / (1000 * 3600 * 24));

    const setNumbers = () => {
        setProjects(appData.projectsList.length);
        setExperience(appData.experiencesList.length);
        setCoffee(differenceInDate * 3);
    };

    const changeNumbers = () => {
        if (!isMobile && window.scrollY >= 300) {
            setNumbers();
        }
    };

    // Effect per lo scroll (principalmente per desktop)
    useEffect(() => {
        window.addEventListener('scroll', changeNumbers);
        return () => {
            window.removeEventListener('scroll', changeNumbers);
        };
    }, [isMobile]);

    // Effect per il caricamento iniziale su mobile
    useEffect(() => {
        if (isMobile) {
            setNumbers();
            setCardDimension("100%")
        }
    }, [isMobile]);

    return (
        <>

            <div className={styles.area}>
                {!isMobile ? <>
                        <Card width={cardDimension} height={cardDimension} padding={""}
                              color={"var(--blue)"} background={"var(--lightBlue)"}>
                            <div className={styles.overview}>
                                <div className={styles.icon}>
                                    <VscIcons.VscGist/>
                                </div>
                                <h3 className={styles.number} id="projects">
                                    <AnimatedCounter
                                        from={0}
                                        to={projects}
                                    />
                                </h3>
                            </div>
                        </Card>

                        <Card width={cardDimension} height={cardDimension} padding={""} color={"var(--blue)"}
                              background={"var(--lightBlue)"}>
                            <div className={styles.overview}>
                                <div className={styles.icon}>
                                    <LiaIcons.LiaUserLockSolid/>
                                </div>
                                <h3 className={styles.number} id="experience">
                                    <AnimatedCounter
                                        from={0}
                                        to={experience}
                                    />
                                </h3>
                            </div>
                        </Card>


                        <Card width={cardDimension} height={cardDimension} padding={""} color={"saddlebrown"}
                              background={"var(--lightBlue)"}>

                            <div className={styles.overview}>
                                <div className={styles.icon}>
                                    <PiCoffeeLight/>
                                </div>
                                <h3 className={styles.number} id="coffee">
                                    <AnimatedCounter
                                        from={0}
                                        to={coffee}
                                    />
                                </h3>
                            </div>
                        </Card>
                    </>
                    : <Card width={cardDimension} height={cardDimension} padding={"10%"} color={"saddlebrown"}
                            background={"var(--lightBlue)"}>

                        <div className={styles.mobileOverview}>

                            <div className={styles.overview}>
                                <div className={styles.icon}>
                                    <VscIcons.VscGist/>
                                </div>
                                <h3 className={styles.number} id="projects">
                                    <AnimatedCounter
                                        from={0}
                                        to={projects}
                                    />
                                </h3>
                            </div>

                            <div className={styles.overview}>
                                <div className={styles.icon}>
                                    <LiaIcons.LiaUserLockSolid/>
                                </div>
                                <h3 className={styles.number} id="experience">
                                    <AnimatedCounter
                                        from={0}
                                        to={experience}
                                    />
                                </h3>
                            </div>

                            <div className={styles.overview}>
                                <div className={styles.icon}>
                                    <PiCoffeeLight/>
                                </div>
                                <h3 className={styles.number} id="coffee">
                                    <AnimatedCounter
                                        from={0}
                                        to={coffee}
                                    />
                                </h3>
                            </div>

                        </div>
                    </Card>}

            </div>
        </>
    );
};

export default Overview;
