import React, { useEffect, useRef, useState } from "react";
import "./ExpandableParagraph.css";

interface ExpandableParagraphProps {
    lines?: number;
    expandText?: string;
    collapseText?: string;
    children: React.ReactNode;
}

export default function ExpandableParagraph({
                                                lines = 5,
                                                expandText = "Visualizza altro",
                                                collapseText = "Visualizza meno",
                                                children
                                            }: ExpandableParagraphProps): JSX.Element {
    const paragraph = useRef<HTMLParagraphElement | null>(null);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [height, setHeight] = useState<string>("13px");
    const [hideButton, setHideButton] = useState<boolean>(false);
    const [lh, setLh] = useState<string | null>(null);

    useEffect(() => {
        if (paragraph.current === null) return;

        if (!expanded) {
            const computedLh = window.getComputedStyle(paragraph.current).lineHeight;
            if (computedLh === "normal") setLh("1.7em");

            const lineHeight = computedLh === "normal" ? "1.7em" : computedLh;
            const numericLineHeight = parseFloat(lineHeight);

            if (lines * numericLineHeight < paragraph.current.scrollHeight) {
                setHeight(`calc(${lines} * ${lineHeight} + 13px)`);
            } else {
                setHeight(`${paragraph.current.scrollHeight}px`);
                setHideButton(true);
            }
        } else {
            setHeight(`${paragraph.current.scrollHeight}px`);
        }
    }, [lines, expanded]);

    return (
        <div>
            <p
                className="expandable-paragraph-text"
                style={{ height }}
                ref={paragraph}
            >
                {children}
            </p>
            <button
                className={`expandable-paragraph-button ${hideButton ? "expandable-paragraph-button-hidden" : ""}`}
                onClick={() => setExpanded((prev) => !prev)}
            >
                {expanded ? collapseText : expandText}
            </button>
        </div>
    );
}