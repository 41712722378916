import React, {useEffect, useRef, useState} from 'react';
import {
    TimelineDot,
    TimelineContent,
    TimelineConnector,
    TimelineSeparator,
    TimelineItem
} from '@mui/lab';
import {Paper} from '@mui/material';
import {FaChalkboardTeacher, FaLaptopCode, FaUserTie, FaTools, FaGraduationCap} from 'react-icons/fa';
import {MdWork} from 'react-icons/md';
import styles from './TimelineItem.module.css'

interface TimelineItemProps {
    img: string;
    title: string;
    period: string;
    place: string;
    external: string;
    index?: number;
    isLast: boolean;
}

const getActivityIcon = (title: string): React.ReactElement => {
    const iconProps = {size: 24};

    switch (title.toLowerCase()) {
        case 'teacher':
        case 'lecturer':
        case 'tutor':
            return <FaChalkboardTeacher {...iconProps} />;
        case 'full-stack developer':
        case 'it consultant':
            return <FaLaptopCode {...iconProps} />;
        case 'technical collaborator':
        case 'collaborator':
            return <FaTools {...iconProps} />;
        case 'worker member':
            return <FaUserTie {...iconProps} />;
        case 'internship':
            return <FaGraduationCap {...iconProps} />;
        default:
            return <MdWork {...iconProps} />;
    }
};

const CustomizedTimelineItem: React.FC<TimelineItemProps> = ({
                                                                 img,
                                                                 title,
                                                                 period,
                                                                 place,
                                                                 external,
                                                                 index = 0,
                                                                 isLast
                                                             }) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const itemRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                    } else {
                        setIsVisible(false);
                    }
                });
            },
            {
                threshold: 0.5,
            }
        );

        if (itemRef.current) {
            observer.observe(itemRef.current);
        }

        return () => {
            if (itemRef.current) {
                observer.unobserve(itemRef.current);
            }
        };
    }, []);

    // Colori per gli stati visibile/non visibile
    const defaultColor = '#bdbdbd';
    const activeColor = '#3f51b5';

    return (
        <TimelineItem ref={itemRef} >
            <TimelineSeparator>
                <TimelineConnector
                    sx={{
                        visibility: index === 0 ? 'hidden' : 'visible',
                        bgcolor: isVisible ? activeColor : defaultColor,
                        transition: 'background-color 0.3s ease'
                    }}
                />
                <TimelineDot
                    sx={{
                        bgcolor: isVisible ? activeColor : defaultColor,
                        transition: 'background-color 0.3s ease'
                    }}
                >
                    {React.cloneElement(getActivityIcon(title), {
                        color: '#fff'
                    })}
                </TimelineDot>
                <TimelineConnector
                    sx={{
                        visibility: isLast ? 'hidden' : 'visible',
                        bgcolor: isVisible ? activeColor : defaultColor,
                        transition: 'background-color 0.3s ease'
                    }}
                />
            </TimelineSeparator>
            <TimelineContent
                sx={{
                    py: '12px',
                    px: 2,
                    display: 'flex',
                    justifyContent: index % 2 !== 0 ? 'flex-end' : 'flex-start'
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
                        opacity: isVisible ? 1 : 0,
                        transition: 'all 0.5s ease'
                    }}
                    className={styles.paper}
                >
                    {img && (
                        <img
                            src={require('../../data/img/logo/' + img)}
                            style={{height: "2rem", marginBottom: "1rem"}}
                            alt={img}
                        />
                    )}
                    <h4 style={{textAlign: "center"}}>{title}</h4>
                    <div><b>Period:</b> {period}</div>
                    <a
                        href={external}
                        style={{
                            color: isVisible ? activeColor : defaultColor,
                            transition: 'color 0.3s ease',
                            textDecoration: 'none'
                        }}
                    >
                        <div>{place}</div>
                    </a>
                </Paper>
            </TimelineContent>
        </TimelineItem>
    );
};

export default CustomizedTimelineItem;